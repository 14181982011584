import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import styled from 'styled-components'
import parse from 'html-react-parser'

const TextDisplay = styled.div`
    background-color : rgba( 255, 255, 255, 0.5 );
    padding: 5%;
    backdrop-filter: blur( 10px )
`

const Wrapper = styled.div`
    width: 80vw;
    min-height: 50vh;
    position: relative;
    background-color: rgba( 0, 0, 0, 0.1 );
    display: flex;
    flex-direction: column;
    background-image: url( '${ props => props.image ? props.image :  '' }' );
    background-position: center;
    background-size: cover;
    background-repeat: no-repect;
    margin-bottom: 10vh;
    padding: 2.5%;

    @media (min-width: 992px) {
        padding-left: ${ props => props.right? '55%' : '2.5%'  }; 
        padding-right: ${ props => props.right? '2.5%' : '55%'  };
    }
`


const Service = props => {

    return ( 
        <Layout
            title = { 'Service' }
        >
            <div 
                style = {{
                    width: '100%',
                    overflow: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                 <Section1
                    content = { parse( props.data.allDatoCmsAbout.edges[0].node.importContent ) }
                    image = { props.data.allDatoCmsAbout.edges[0].node.wholesaleImage.url ? props.data.allDatoCmsAbout.edges[0].node.importImage.url : null }
                />
                
                <Section2
                    right
                    content = { parse( props.data.allDatoCmsAbout.edges[0].node.wholesaleContent ) }
                    image = { props.data.allDatoCmsAbout.edges[0].node.wholesaleImage.url ? props.data.allDatoCmsAbout.edges[0].node.wholesaleImage.url : null }
                />
            </div>
        </Layout>
    )
}
export default Service

const Section1 = props => {

    return(
        <Wrapper 
            image = { 'https://cdn.shopify.com/s/files/1/0077/2505/6077/files/import.jpg?v=1611432338' }
            right = { props.right }
        >
            <TextDisplay>
                { props.content }
            </TextDisplay>
        </Wrapper>
    )
}

const Section2 = props => {

    return(
        <Wrapper 
            image = { 'https://cdn.shopify.com/s/files/1/0077/2505/6077/files/wholesale.jpg?v=1611432338' }
            right = { props.right }
        >
            <TextDisplay>
                { props.content }
            </TextDisplay>
        </Wrapper>
    )
}

export const query = graphql`
    query ServiceQuery {
        allDatoCmsAbout {
            edges {
              node {
                wholesaleContent
                wholesaleImage {
                  url
                }
                importContent
                importImage {
                  url
                }
              }
            }
        }
    }
`